<template>
  <div class="modal fade" id="serviceTipsViewModal" tabindex="-1" role="dialog" aria-labelledby="serviceTipsViewModal"
       aria-hidden="true">
    <div class="modal-dialog modal-md modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header bg-primary">
          <h1 class="modal-title white">{{ service.name }} Tips</h1>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <i class="bx bx-x"></i>
          </button>
        </div>
        <div class="modal-body">
          <div>
            <div class="row">
              <ul v-if="serviceTips.length > 0">
                <li v-for="(serviceTip,index) in serviceTips" :key="index">
                  <span>{{ serviceTip.tips }}</span>
                </li>
              </ul>
              <div v-else>No Tips for the service "{{ service.name }}"</div>

            </div>
          </div>
        </div>
        <div class="modal-footer border-0 pt-0">
          <button type="button" class="btn btn-primary" data-dismiss="modal">
            <i class="bx bx-x d-block d-sm-none"></i>
            <span class="d-none d-sm-block">Close</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import Loader from "@/components/backEnd/mixins/Loader";
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";

export default {
  name: "ServiceTipsViewModal",
  props: ['service'],
  mixins: [Loader, ShowToastMessage],
  data() {
    return {
      getServiceTipsParams: {
        service_id: null,
      },
    };
  },
  computed: {
    ... mapGetters({
      serviceTips: 'appServiceTips/serviceTips',

    })

  },

  watch: {
    service(service) {
      if (service.id) {
        this.getServiceTipsParams.service_id = service.id;
        this.getServiceTipList()
      }
    }
  },
  methods: {
    ... mapActions({
      getServiceTips: 'appServiceTips/getServiceTips'
    }),

    async getServiceTipList() {
      await this.loader(true);
      await this.getServiceTips(this.getServiceTipsParams).then(async response => {
        await this.loader(false);
        if (response !== 200) {
          await this.showToastMessage(response);
        }
      })
    }
  },

};
</script>

<style scoped>

</style>
