<template>
  <div  class="modal fade" id="servicePricesViewModal" tabindex="-1" role="dialog"
       aria-labelledby="servicePricesViewModal"
       aria-hidden="true">
    <div class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content" style="background-color: #F2F4F4;">
        <div class="modal-header bg-primary">
                    <h1 class="modal-title white">{{ service.name }}</h1>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <i class="bx bx-x"></i>
          </button>
        </div>

        <div class="modal-body">

          <div class="row">
            <div class="col-md-4">
              <div class="">
                <div class="">
                  <div>
                    <div class="d-flex align-items-center mb-1">
                      <div>
                        <h5 class="text-bold-400 text-capitalize" style="text-transform: capitalize;">
                          Base Price
                        </h5>
                      </div>
                    </div>
                    <ul class="list-unstyled">
                      <li class="mb-1">
                        <span class="text-bold-700 mb-1 text-capitalize" style="text-transform: capitalize;">Home Price: </span>
                        <span>{{ parseFloat(service.home_price /100).toFixed(2) }}</span>
                      </li>
                      <li class="mb-1">
                        <span class="text-bold-700 mb-1 text-capitalize" style="text-transform: capitalize;">Business Price: </span>
                        <span>{{ parseFloat(service.business_price /100).toFixed(2) }}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-8">
              <div>
                <div>
                  <h5 class="text-bold-400 text-capitalize" style="text-transform: capitalize;">
                    Statewise Price
                  </h5>
                </div>

                <table id="users-list-datatable" class="table">
                  <thead>
                  <tr>
                    <th class="position-relative" :style="{width:'20%'}">STATE

                    </th>
                    <th class="position-relative" :style="{width:'15%'}">HOME PRICE

                    </th>
                    <th class="position-relative" :style="{width:'15%'}">BUSINESS PRICE

                    </th>

                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(statePrice, index) in statePriceList" :key="index">
                    <td>{{ statePrice.state }}</td>
                    <td>{{ statePrice.home_price }}</td>
                    <td>{{ statePrice.business_price }}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
            </div>
          </div>
        <div class="modal-footer border-0 pt-0">
          <button type="button" class="btn btn-primary" data-dismiss="modal">
            <i class="bx bx-x d-block d-sm-none"></i>
            <span class="d-none d-sm-block">Close</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loader from "@/components/backEnd/mixins/Loader";
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "ServicePricesViewModal.vue",
  props: ['service'],
  mixins: [Loader, ShowToastMessage],
  data() {
    return {
      statePriceList:[],
      getSettingsParams: {
        type: ['service'],
        key: ['service_state_price_rate']
      },
      getServicePricesParams: {
        service_id: null,
        home_price: "",
        business_price: "",
      },
    };
  },

  watch: {
    service(currentService) {
      this.statePriceList = this.settingServiceStatePriceRate.value ? this.settingServiceStatePriceRate.value.map(item => {
        return {
          state: item.state.toUpperCase(),
          home_price: currentService.home_price ? (currentService.home_price * item.rate / 100).toFixed(2) : 0,
          business_price: currentService.business_price ? (currentService.business_price * item.rate / 100).toFixed(2) : 0,
        }
      }) : [];

    },

  },
  computed: {
    ...mapGetters({
      settingServiceStatePriceRate: 'appSettings/settingServiceStatePriceRate',

    }),
  },
  methods: {
    ...mapActions({
      getSettings: 'appSettings/getSettings',

    }),
   async getSettingList(){
      await this.getSettings(this.getSettingsParams);
    }
  },
 async mounted() {
    await this.getSettingList();
  }
}
</script>

<style scoped>

</style>