<template>
<AppLayout>
  <template v-slot:appContent>
    <div>
      <div class="d-flex justify-content-between align-items-center pt-2 pt-md-2 pt-xl-0">
        <h4 class="">Services/Create</h4>
        <div>
          <router-link :to="{ ...previousRoute }">
            <span class="glow d-flex align-items-center"><i class="bx bx-undo"></i>&nbsp;Back</span>
          </router-link>
        </div>
      </div>

      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-12 col-sm-12 col-lg-4 col-md-6">
              <div class="form-group">
                <div class="">
                  <label>Service name</label>
                  <input type="text" class="form-control" placeholder="Enter service name " v-model="name" />
                </div>
                <div v-if="errors.name !== undefined && errors.name.length > 0">
                  <p v-for="(error, index) in errors.name" :key="index" class="text-danger mb-1">
                    {{ error }}
                  </p>
                </div>
              </div>
            </div>

            <div class="col-12 col-sm-12 col-lg-4 col-md-6">
              <div class="form-group">
                <div class="controls">
                  <label>Service Category</label>
                  <VueMultiselect v-model="selectedServiceCategory" class="multiselect-blue" :options="serviceCategoryList" :close-on-select="true" :allow-empty="false" placeholder="Select service category" label="name" track-by="name" :show-labels="false" />
                </div>
                <div v-if="
                      errors.service_category_id !== undefined &&
                      errors.service_category_id.length > 0
                    ">
                  <p v-for="(error, index) in errors.service_category_id" :key="index" class="text-danger mb-1">
                    {{ error }}
                  </p>
                </div>
              </div>
            </div>

            <div class="col-12 col-sm-12 col-lg-4 col-md-6">
              <div class="form-group">
                <div class="">
                  <label>Business price</label>
                  <input type="number" v-model="businessPrice" class="form-control" placeholder="Enter business price" name="businessPrice" />
                </div>
                <div v-if="
                      errors.business_price !== undefined &&
                      errors.business_price.length > 0
                    ">
                  <p v-for="(error, index) in errors.business_price" :key="index" class="text-danger mb-1">
                    {{ error }}
                  </p>
                </div>
              </div>
            </div>

            <div class="col-12 col-sm-12 col-lg-4 col-md-6">
              <div class="form-group">
                <div class="">
                  <label>Home price</label>
                  <input type="number" class="form-control" placeholder="Enter home price" v-model="homePrice" name="homePrice" />
                </div>
                <div v-if="errors.home_price !== undefined && errors.home_price.length > 0">
                  <p v-for="(error, index) in errors.home_price" :key="index" class="text-danger mb-1">
                    {{ error }}
                  </p>
                </div>
              </div>
            </div>

            <div class="col-12 col-sm-12 col-lg-4 col-md-6">
              <div class="form-group">
                <div class="controls">
                  <label>Status</label>
                  <VueMultiselect v-model="selectedStatus" class="multiselect-blue" :options="statusList" :allow-empty="false" placeholder="Select status" label="name" track-by="name" :close-on-select="true" :show-labels="false" />
                </div>
                <div v-if="errors.status !== undefined && errors.status.length > 0">
                  <p v-for="(error, index) in errors.status" :key="index" class="text-danger mb-1">
                    {{ error }}
                  </p>
                </div>
              </div>
            </div>

            <div class="col-12 col-sm-12 col-lg-4 col-md-6">
              <div class="form-group">
                <div class="controls">
                  <label>Commission</label>
                  <VueMultiselect v-model="selectedCommissionStatus" class="multiselect-blue" :options="commissionStatusList" :close-on-select="true" :allow-empty="false" placeholder="Select commission status" label="name" track-by="name" :show-labels="false" />
                </div>
                <div v-if="errors.commission !== undefined && errors.commission.length > 0">
                  <p v-for="(error, index) in errors.commission" :key="index" class="text-danger mb-1">
                    {{ error }}
                  </p>
                </div>
              </div>
            </div>

            <div class="col-12 col-sm-12 col-lg-4 col-md-6">
              <div class="form-group">
                <div class="controls">
                  <label>Show Website</label>
                  <VueMultiselect v-model="selectedShowWebStatus" class="multiselect-blue" :options="showWebStatusList" :close-on-select="true" placeholder="Select show website status" :allow-empty="false" label="name" track-by="name" :show-labels="false" />
                </div>
                <div v-if="errors.show_website !== undefined && errors.show_website.length > 0">
                  <p v-for="(error, index) in errors.show_website" :key="index" class="text-danger mb-1">
                    {{ error }}
                  </p>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-12 col-lg-4 col-md-6">
              <div class="form-group">
                <div class="controls">
                  <label>Show Home</label>
                  <VueMultiselect v-model="selectedShowHomeStatus" class="multiselect-blue" :options="showHomeStatusList" :close-on-select="true" placeholder="Select show home status" :allow-empty="false" label="name" track-by="name" :show-labels="false" />
                </div>
                <div v-if="errors.show_home !== undefined && errors.show_home.length > 0">
                  <p v-for="(error, index) in errors.show_home" :key="index" class="text-danger mb-1">
                    {{ error }}
                  </p>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-12 col-lg-4 col-md-6">
              <div class="form-group">
                <div class="controls">
                  <label>Show Online Appointment </label>
                  <VueMultiselect v-model="selectedShowOnlineAppointmentStatus" class="multiselect-blue" :options="showOnlineAppointmentStatusList" :close-on-select="true" placeholder="Select show Online Appointment status" :allow-empty="false" label="name" track-by="name" :show-labels="false" />
                </div>
                <div v-if="errors.show_online_appointment !== undefined && errors.show_online_appointment.length > 0">
                  <p v-for="(error, index) in errors.show_online_appointment" :key="index" class="text-danger mb-1">
                    {{ error }}
                  </p>
                </div>
              </div>
            </div>

            <div class="col-12 col-sm-12 col-lg-4 col-md-6">
              <div class="form-group">
                <div class="controls">
                  <label>Meta Author</label>
                  <input type="text" class="form-control" placeholder="Enter meta author" v-model="metaAuthor" name="author" />
                </div>
                <div v-if="
                      errors.meta_author !== undefined && errors.meta_author.length > 0
                    ">
                  <p v-for="(error, index) in errors.meta_author" :key="index" class="text-danger mb-1">
                    {{ error }}
                  </p>
                </div>
              </div>
            </div>
            <!-- source  -->
            <div class="col-12 col-sm-12 col-lg-4 col-md-6">
              <div class="form-group">
                <div class="controls">
                  <label>Source</label>
                  <VueMultiselect v-model="selectedSourceStatus" class="multiselect-blue" :options="sourceList" :close-on-select="true" placeholder="Select source" :allow-empty="false" label="name" track-by="name" :show-labels="false" />
                </div>
                <div v-if="errors.show_online_appointment !== undefined && errors.show_online_appointment.length > 0">
                  <p v-for="(error, index) in errors.show_online_appointment" :key="index" class="text-danger mb-1">
                    {{ error }}
                  </p>
                </div>
              </div>
            </div>

            <div class="col-12">
              <div class="form-group">
                <div class="controls">
                  <label>Meta Keywords</label>
                  <textarea v-model="metaKeywords" class="form-control" placeholder="Enter meta keywords"></textarea>
                </div>
                <div v-if="
                      errors.meta_keywords !== undefined &&
                      errors.meta_keywords.length > 0
                    ">
                  <p v-for="(error, index) in errors.meta_keywords" :key="index" class="text-danger mb-1">
                    {{ error }}
                  </p>
                </div>
              </div>
            </div>

            <div class="col-12">
              <div class="form-group">
                <div class="controls">
                  <label>Meta Description</label>
                  <textarea v-model="metaDescription" class="form-control" placeholder="Enter meta description"></textarea>
                </div>
                <div v-if="
                      errors.meta_description !== undefined &&
                      errors.meta_description.length > 0
                    ">
                  <p v-for="(error, index) in errors.meta_description" :key="index" class="text-danger mb-1">
                    {{ error }}
                  </p>
                </div>
              </div>
            </div>

            <div class="col-12">
              <div class="form-group">
                <div class="controls">
                  <label>Other Meta Data</label>
                  <textarea v-model="otherMetaData" class="form-control" placeholder="Enter other meta data"></textarea>
                </div>
                <div v-if="
                      errors.other_meta_data !== undefined &&
                      errors.other_meta_data.length > 0
                    ">
                  <p v-for="(error, index) in errors.other_meta_data" :key="index" class="text-danger mb-1">
                    {{ error }}
                  </p>
                </div>
              </div>
            </div>

            <div class="col-12">
              <div class="form-group">
                <label>Description</label>
                <QuillEditor v-model:content="description" contentType="html" toolbar="full" theme="snow" />
              </div>
            </div>
          </div>

          <div class="row mb-4">
            <div class="col-12">
              <div class="d-flex justify-content-end">
                <button @click="createServiceHandler" type="button" class="btn btn-primary update-todo px-4">
                  Create Service
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
</AppLayout>
</template>

<script>
// components
import AppLayout from "@/layouts/backEnd/AppLayout.vue";
// mixins
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";
import Authorization from "@/components/backEnd/mixins/Authorization";
// 3rd party packages
import VueMultiselect from "vue-multiselect";
import {
  QuillEditor
} from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
// core packages
import {
  mapActions,
  mapGetters
} from "vuex";

export default {
  components: {
    AppLayout,
    VueMultiselect,
    QuillEditor,
  },
  name: "ServiceCreate",
  mixins: [ShowToastMessage, Loader, Authorization],
  data() {
    return {
      selectedSourceStatus: {
        value: 0,
        name: "Default",
      },
      getServiceCategoriesParams: {
        status: 1,
        order_by_name: "ASC",
      },
      otherMetaData: "",
      metaDescription: "",
      metaKeywords: "",
      metaAuthor: "",
      selectedServiceCategory: {
        value: "",
        name: "None",
      },
      selectedStatus: {
        value: 1,
        name: "Active",
      },
      selectedCommissionStatus: {
        value: 0,
        name: "No",
      },
      selectedShowWebStatus: {
        value: 1,
        name: "Yes",
      },
      selectedShowHomeStatus: {
        value: 1,
        name: "Yes",
      },
      selectedShowOnlineAppointmentStatus: {
        value: 1,
        name: "Yes",
      },

      businessPrice: "",
      homePrice: "",
      description: "",
      name: "",
      errors: {},
    };
  },
  computed: {
    ...mapGetters({
      previousRoute: "previousRoute",
      serviceCategories: "appServiceCategories/serviceCategories",
      authUser: "appAuthLogin/authUser",
    }),
    redirectRouteName() {
      return this.can("service-view-any") ? "appServiceList" : "appServiceCreate";
    },
    serviceCategoryList() {
      let serviceCategories = this.serviceCategories ?
        this.serviceCategories.map((serviceCategory) => {
          return {
            value: serviceCategory.id,
            name: serviceCategory.name,
          };
        }) :
        [];

      return [{
          value: "",
          name: "None",
        },
        ...serviceCategories,
      ];
    },
    statusList() {
      return [{
          value: 1,
          name: "Active",
        },
        {
          value: 0,
          name: "Inactive",
        },
      ];
    },
    commissionStatusList() {
      return [{
          value: 1,
          name: "Yes",
        },
        {
          value: 0,
          name: "No",
        },
      ];
    },
    showWebStatusList() {
      return [{
          value: 1,
          name: "Yes",
        },
        {
          value: 0,
          name: "No",
        },
      ];
    },
    showHomeStatusList() {
      return [{
          value: 1,
          name: "Yes",
        },
        {
          value: 0,
          name: "No",
        },
      ];
    },
    showOnlineAppointmentStatusList() {
      return [{
          value: 1,
          name: "Yes",
        },
        {
          value: 0,
          name: "No",
        },
      ];
    },
    sourceList() {
      let sources = this.$store.getters['appReferrals/referrals'].map(item => {
        return {
          value: item.id,
          name: item.code,
        };
      })
      return [this.selectedSourceStatus, ...sources];
    },
  },
  watch: {},
  methods: {
    ...mapActions({
      getServiceCategories: "appServiceCategories/getServiceCategories",
      postService: "appService/postService",
      getReferral: "appReferrals/getReferrals"
    }),
    async getServiceCategoryList() {
      await this.getServiceCategories(this.getServiceCategoriesParams);
    },
    async createServiceHandler() {
      this.loader(true);
      let data = {
        added_by: this.authUser.id,
        name: this.name,
        description: this.description,
        meta_description: this.metaDescription,
        meta_keywords: this.metaKeywords,
        meta_author: this.metaAuthor,
        other_meta_data: this.otherMetaData,
        //price convert dollar to cent
        home_price: this.homePrice * 100,
        business_price: this.businessPrice * 100,

        commission: this.selectedCommissionStatus.value,
        status: this.selectedStatus.value,
        source: this.selectedSourceStatus.value,
        show_website: this.selectedShowWebStatus.value,
        show_home: this.selectedShowHomeStatus.value,
        show_online_appointment: this.selectedShowOnlineAppointmentStatus.value,
        service_category_id: this.selectedServiceCategory.value,
      };

      const response = await this.postService(data);
      this.loader(false);
      if (response.status === 200 || response.status === 201) {
        let toastObj = {
          message: "Service Successfully Created",
          type: "success",
        };
        this.showToastMessage(toastObj);
        await this.$router.push({
          name: this.redirectRouteName,
        });
        this.errors = {};
        return;
      }
      this.errors = response.errors;
      if (response.message) {
        this.showToastMessage(response);
      }
    },
  },

  async mounted() {
    await this.loader(true);
    await this.getServiceCategoryList();
    await this.getReferral();
    await this.loader(false);
  },
};
</script>

<style src="../../../../node_modules/vue-multiselect/dist/vue-multiselect.css"></style><style>
.multiselect__tags:focus-within {
  /* border:1px solid red !important; */
  border-color: #5a8dee;
}

.multiselect__tags {
  min-height: 0;
  max-height: 38px !important;
  border-color: #dfe3e7;
}

.ql-editor {
  min-height: 200px !important;
}

.generate-password-btn {
  color: #2c6de9;
}
</style>
