<template>
  <AppLayout>
    <template v-slot:appContent>
      <section class="users-list-wrapper">
        <div class="d-flex justify-content-between align-items-center pt-2 pt-md-2 pt-xl-0 ">
          <h4 class="">Services/List</h4>
          <div>
            <router-link :to="can('service-create') ? {name:'appServiceCreate'} : '#'">
              <span :title="can('service-create') ? 'Create' : 'Create Forbidden'"
                    class="glow d-flex align-items-center">
                <i class='bx bx-plus bx-flip-vertical'></i>&nbsp;Create</span>
            </router-link>
          </div>
        </div>
        <div class="users-list-filter px-1 my-2 py-2 border rounded">
          <div class="row align-items-center">
            <div class="col-12 col-sm-12 col-md-3">
              <label>Show Per Page</label>
              <VueMultiselect v-model="selectedPagination" :options="paginationList" :close-on-select="true"
                              label="name" track-by="value" :show-labels="false" :allow-empty="false"/>
            </div>
            <div class="col-12 col-sm-12 col-md-3">
              <label>Category</label>
              <VueMultiselect v-model="selectedServiceCategory" class="multiselect-blue"
                              :options="serviceCategoryList" :close-on-select="true"
                              :allow-empty="false" placeholder="Select service category"
                              label="name" track-by="name" :show-labels="false"/>
            </div>
            <div class="col-12 col-sm-12 col-md-3">
              <label>Status</label>
              <VueMultiselect :allow-empty="false" v-model="selectedStatus" class="" :options="status"
                              :close-on-select="true" placeholder="Select status" label="name" track-by="value"
                              :show-labels="false"/>
            </div>
            <div class="col-12 col-sm-12 col-md-3">
              <label>Source</label>
              <VueMultiselect :allow-empty="false" v-model="selectedSource" class="" :options="sourceOption"
                              :close-on-select="true" placeholder="Select source" label="name" track-by="value"
                              :show-labels="false"/>
            </div>
          </div>
          <div class="row pt-2">
            <div class="col-12 col-sm-12 col-md-9">
              <input v-model="getServiceParams.name_code_query" class="form-control search-admin-input-element"
                     type="text"
                     placeholder="Search by name or code">
            </div>
            <div class="col-12 col-sm-12 col-md-3">
              <button type="reset" class="btn btn-primary btn-block glow users-list-clear mb-0"
                      @click="applyServiceFilter(null)">Filter
              </button>
            </div>
          </div>
        </div>
        <div class="users-list-table">
          <div class="card">
            <div class="card-body">
              <div class="table-responsive" ref="serviceListRef">
                <table id="users-list-datatable" class="table">
                  <thead>
                  <tr>
                    <th class="position-relative" :style="{ width: '5%' }">SL</th>
                    <th class="position-relative" :style="{ width: '10%' }">Image</th>
                    <th class="position-relative" :style="{ width: '25%' }">Name</th>
                    <th class="position-relative" :style="{ width: '10%' }">Code</th>
                    <th class="position-relative" :style="{ width: '9%' }">Show Home</th>
                    <th class="position-relative" :style="{ width: '9%' }">Show Website</th>
                    <th class="position-relative" :style="{ width: '9%' }">Show Online Appointment</th>
                    <th class="position-relative" :style="{ width: '9%' }">Status</th>
                    <th class="position-relative" :style="{ width: '10%'}">Source</th>
                    <th class="position-relative" :style="{ width: '9%' }">Actions</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(service, index) in services" :key="index">
                    <td>
                      {{ index + 1 }}
                    </td>
                    <td>
                      <img :src="service.thumbnail" class="img-fluid " style="height: 2rem;">
                    </td>
                    <td class="text-capitalize">
                      {{ service.name }}
                    </td>
                    <td>
                      {{ service.code }}
                    </td>
                    <td>
                      <span class="badge"
                            :class="service.show_home === 'Active'? 'badge-light-success': 'badge-light-danger'">
                                                {{ service.show_home }}
                      </span>
                    </td>
                    <td>
                      <span class="badge"
                            :class="service.show_website === 'Active'? 'badge-light-success': 'badge-light-danger'">
                                                {{ service.show_website }}
                      </span>
                    </td>
                    <td>
                      <span class="badge"
                            :class="service.show_online_appointment === 'Active'? 'badge-light-success': 'badge-light-danger'">
                                                {{ service.show_online_appointment }}
                      </span>
                    </td>
                    <td>
                      <span class="badge"
                            :class="service.status === 'Active'? 'badge-light-success': 'badge-light-danger'">
                                                {{ service.status }}
                      </span>
                    </td>
                    <td>
                      <span class="badge"
                            :class="service.source === 'Default'? 'badge-light-warning': 'badge-light-danger'">
                                                {{ service.source }}
                      </span>
                    </td>
                    <td>
                      <div class="d-flex align-items-top justify-content-around">
                        <div>
                          <router-link
                              :to="can('service-view') ? {name: 'appServiceView',params: { id: service.id }} : '#'"
                              :title="can('service-view') ? 'View' : 'View Forbidden'"
                              class="cursor-pointer view-edit-delete-icon"
                              :style="{ height: '18px' }">
                            <i class="bx bx-show"></i>
                          </router-link>

                        </div>
                        <i title="view price" class='bx bx-dollar cursor-pointer view-edit-delete-icon'
                           @click="viewServicePriceModalOpenHandler(service.id)"></i>
                        <div class="dropdown py-0 px-0 view-edit-delte-icon" :style="{height:'18px'}">
                                                    <span type="button" class="px-0 py-0" data-toggle="dropdown"
                                                          aria-haspopup="true" aria-expanded="false">
                                                        <i class='bx bx-dots-vertical-rounded h-100'></i>
                                                    </span>
                          <div class="dropdown-menu" style="min-height: 100px">
                            <button :disabled="!can('service-tip-create')" class="dropdown-item"
                                    :title="can('service-tip-create') ? 'Add Tips' : 'Add Tips Forbidden'"
                                    @click="openCreateServiceModal(service.id)">
                              Add Tips
                            </button>
                            <button :disabled="!can('service-tip-view')" class="dropdown-item"
                                    :title="can('service-tip-view') ? 'View Tips' : 'View Tips Forbidden'"
                                    @click="getServiceTips(service.id)">
                              View Tips
                            </button>
                            <button :disabled="!can('service-delete')" @click="modelIdForDelete = service.id"
                                    data-toggle="modal"
                                    :title="can('service-delete') ? 'Delete' : 'Delete Forbidden'"
                                    data-target="#deleteServiceModal" class="dropdown-item">Delete Service
                            </button>

                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
                <ListPagination position="right" :pagination-data="paginateLinks" @getClickedPage="applyServiceFilter"/>
              </div>
            </div>
          </div>
          <div class="" data-toggle="modal" data-target="#serviceTipsCreateModal"></div>
          <div class="" data-toggle="modal" data-target="#serviceTipsViewModal"></div>
          <div class="" data-toggle="modal" data-target="#servicePricesViewModal"></div>
          <ServiceTipsCreateModal :service="{...selectedService}"/>

          <ServiceTipsViewModal :service="{...selectedService}"/>
          <ServicePricesViewModal :service="{...selectedService}"/>
          <ServiceDeleteAlertModal modal-name="deleteServiceModal" model-name="service" :model-id="modelIdForDelete"
                                   @confirmModelDeletion="onConfirmModelDeletion"/>

        </div>
      </section>
    </template>
  </AppLayout>
</template>

<script>
// components
import AppLayout from "@/layouts/backEnd/AppLayout.vue";
import ListPagination from "@/components/backEnd/pagination/ListPagination";
import ServiceDeleteAlertModal from "@/components/backEnd/modal/DeleteAlertModal";
import ServiceTipsCreateModal from "@/views/backEnd/services/includes/ServiceTipsCreateModal";
import ServiceTipsViewModal from "@/views/backEnd/services/includes/ServiceTipsViewModal";
import ServicePricesViewModal from "@/views/backEnd/services/includes/ServicePricesViewModal";
// mixins
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Authorization from "@/components/backEnd/mixins/Authorization";
import Loader from "@/components/backEnd/mixins/Loader";
// core packages
import {mapActions, mapGetters} from "vuex";

export default {
  name: "ServiceList",
  mixins: [ShowToastMessage, Loader, Authorization],
  components: {
    AppLayout,
    ServiceDeleteAlertModal,
    ListPagination,
    ServiceTipsCreateModal,
    ServiceTipsViewModal,
    ServicePricesViewModal,

  },
  data() {
    return {
      selectedSource: {
        value: 0,
        name: "Default",
      },
      getServiceCategoriesParams: {
        status: 1,
        order_by_name: 'ASC',
      },
      selectedServiceCategory: {
        value: '',
        name: 'Any'
      },
      selectedPagination: {
        value: '',
        name: 'Default'
      },
      modelIdForDelete: '',
      getServiceParams: {
        status: "",
        source: "",
        service_category_id: "",
        order_by_name: "ASC",
        name_code_query: "",
        with_relation: ['media'],
        paginate: 1,
        pagination: "",
        page: "",
      },

      selectedStatus: {
        value: "",
        name: "Any",
      },
      getSettingsParams: {
        type: ["default"],
        key: ["default_pagination"],
      },
      status: [{
        value: 0,
        name: "Inactive",
      },
        {
          value: 1,
          name: "Active",
        },
        {
          value: "",
          name: "Any",
        },
      ],
      selectedService: {},
    };
  },
  computed: {
    ...mapGetters({
      serviceCategories: "appServiceCategories/serviceCategories",
      services: "appService/services",
      paginateLinks: "appService/paginateLinks",
    }),
    paginationList() {
      return [{
        value: this.$store.getters['appSettings/settingDefaultPagination'].value,
        name: 'Default'
      }, {
        value: 25,
        name: '25 Entries'
      }, {
        value: 50,
        name: '50 Entries'
      }, {
        value: 100,
        name: '100 Entries'
      }];
    },
    serviceCategoryList() {
      let serviceCategories = this.serviceCategories ? this.serviceCategories.map(serviceCategory => {
        return {
          value: serviceCategory.id,
          name: serviceCategory.name
        }
      }) : [];

      return [
        {
          value: '',
          name: 'None'
        },
        ...serviceCategories,
      ];
    },

    statusList() {
      return [
        {
          value: '',
          name: 'Any'
        },
        {
          value: 0,
          name: 'Inactive'
        },
        {
          value: 1,
          name: 'Active'
        },
      ];
    },
    sourceOption() {
      let sources = this.$store.getters['appReferrals/referrals'].map(item => {
        return {
          value: item.id,
          name: item.code,
        };
      })
      return [
        {
          value: 0,
          name: "Default",
        }, ...sources];
    },

  },
  watch: {
    selectedPagination(selectedPagination) {
      this.getServiceParams.pagination = selectedPagination.value;
    },
    selectedServiceCategory(selectedServiceCategory) {
      this.getServiceParams.service_category_id = selectedServiceCategory.value;
    },
    async selectedStatus(status) {
      this.getServiceParams.status = status.value !== "" ? status.value : "";
    },
    async selectedSource(source) {
      this.getServiceParams.source = source.value;
    },
  },

  methods: {
    ...mapActions({
      resetServices: "appService/resetServices",
      getReferral: "appReferrals/getReferrals",
      getSettings: "appSettings/getSettings",
      getServiceCategories: "appServiceCategories/getServiceCategories",
      getServices: "appService/getServices",
      deleteServiceOnList: "appService/deleteServiceOnList",
    }),

    async getSettingList() {
      await this.getSettings(this.getSettingsParams);
    },

    async getServiceList() {
      await this.getServices(this.getServiceParams).then((response) => {
        if (response && response.message && response.status !== 200) {
          this.showToastMessage(response);
        }
      });
    },

    async applyServiceFilter(pageNumber) {
      this.getServiceParams.page = pageNumber;
      await this.loader(true);
      await this.getServiceList(this.getServiceParams);
      await this.loader(false);
    },

    async openCreateServiceModal(serviceId) {
      this.selectedService = {};
      // console.log(serviceId);
      this.selectedService = await this.services.find((service) => service.id === serviceId);
      await this.toggleModal('serviceTipsCreateModal');
    },

    async getServiceTips(serviceId) {
      this.selectedService = {};
      this.selectedService = await this.services.find((service) => service.id === serviceId);
      await this.toggleModal('serviceTipsViewModal');
    },
    // async getServicePrices(serviceId) {
    //   this.selectedService = {};
    //   this.selectedService = await this.services.find((service) => service.id === serviceId);
    //   await this.toggleModal('servicePricesViewModal');
    // },
    findService(id) {
      this.service = {};
      this.service = this.services.find((service) => service.id === id);
    },

    async viewServicePriceModalOpenHandler(id) {
      this.selectedService = {};
      this.selectedService = await this.services.find((service) => service.id === id);
      document.querySelector('[data-target="#servicePricesViewModal"]').click();
    },

    async deleteSingleServiceOnList(id) {
      this.deleteServiceOnList(id).then(async (response) => {
        if (response.status === 200 || response.status === 204) {
          this.showToastMessage({type: 'success', message: 'Service deleted successfully'});
          return;
        }
        this.showToastMessage(response);
      });
    },

    async onConfirmModelDeletion(confirmModelDeletion) {
      await this.deleteSingleServiceOnList(confirmModelDeletion.modelId);
    },

    async toggleModal(modalName) {
      document.querySelector(`[data-target="#${modalName}"]`).click(); // modal close
    },
    async getServiceCategoryList() {
      await this.getServiceCategories(this.getServiceCategoriesParams);
    },

  },

  async mounted() {
    await this.loader(true);
    await this.getSettingList();
    await this.getServiceCategoryList();
    await this.getServiceList();
    await this.getReferral();
    await this.loader(false);
  },

  beforeMount() {
    this.resetServices();
  },


};
</script>

<style scoped>
</style>
