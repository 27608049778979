<template>
  <div class="modal fade" id="serviceTipsCreateModal" tabindex="-1" role="dialog" aria-labelledby="serviceTipsCreateModal"
       aria-hidden="true">
    <div class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header bg-primary">
          <h1 class="modal-title white">Create {{ service.name }} Service's Tip</h1>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <i class="bx bx-x"></i>
          </button>
        </div>
        <div class="modal-body">
          <div>
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label>Create Service Tips</label>
                  <textarea v-model="tips" class="form-control" name="" id="" rows="10"></textarea>
                  <div v-if="errors.tips !== undefined && errors.tips.length > 0">
                    <p v-for="(error,index) in  errors.tips" :key="index" class="text-danger mb-1">
                      {{ error }}
                    </p>

                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
        <div class="modal-footer border-0 pt-0">
          <button type="button" @click="createServiceTips" class="btn btn-primary">
            <i class="bx bx-x d-block d-sm-none"></i>
            <span class="d-none d-sm-block">Create</span>
          </button>
        </div>


      </div>
    </div>
  </div>
</template>

<script>
import {
  mapActions,

} from "vuex";
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";
export default {
  name: "ServiceTipsCreateModal",
  mixins: [ShowToastMessage, Loader],
  props: ['service'],
  data() {
    return {
      tips: '',

      errors: {},
      frontEndErrors: {
        serviceTips: [],
      }
    };
  },
  watch: {},
  methods: {
    ...mapActions({
      postServiceTips: 'appServiceTips/postServiceTip',

    }),
    async createServiceTips() {
      this.loader(true);
      const data = {
        service_id: this.service.id,
        tips: this.tips

      }

      await this.postServiceTips(data).then(async (response) => {

        if (response.status === 200 || response.status === 201) {
          document.querySelector('[data-target="#serviceTipsCreateModal"]').click();
          let successToastObj = {message: 'Service tips create successfully.', type: 'success'};
          this.showToastMessage(successToastObj);
          this.loader(false);
          this.resetData();

        } else {
          this.loader(false);
          this.errors = response.errors;
        }
        if (response.message) {
          this.showToastMessage(response);
        }

        this.errors.service_id = response?.errors?.service_id?.[0] ?? '';
        this.errors.tips = response?.errors?.tips?.[0] ?? '';

        this.showToastMessage(response);

      });

    },
    resetData() {
      this.tips = "";
      this.errors = {}
    }
  },
  computed: {},

};
</script>

<style scoped>

</style>
